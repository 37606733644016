<script lang="ts" setup>
const { t } = useT();
const isGuest = useIsGuest();
const { data } = useAppInitData();
const { handleSupportButtonClick } = useSupportChat();
const { isEventTheme } = useEventsCollector();

const hasDeposits = computed(() => data.value?.hasDeposits);
const logoImg = computed(() => `/nuxt-img/${isEventTheme.value ? "black-friday-" : ""}logo-icon.svg`);
const isShowAffiliate = computed(() => !isGuest.value && data.value?.isAffiliate);

const termsLinks = computed(() =>
	data.value?.footer?.menu
		?.find((item) => item?.title === "second_column")
		?.items?.filter(
			(link) => !((link?.slug === "promotional-games-rules" || link?.slug === "refund-policy") && !hasDeposits.value)
		)
		?.filter(
			(link) => !((link?.slug === "affiliate-agreement" || link?.slug === "partners-rules") && !isShowAffiliate.value)
		)
		?.filter((link) => !(link?.slug === "alternative-method-of-entry" && isGuest.value))
);

const expandedItems = ref<Record<string, boolean>>({
	about: false,
	how: false,
	additional: false
});

const handleClick = (key: string) => {
	expandedItems.value = Object.keys(expandedItems.value).reduce(
		(acc, expandedItem) => {
			acc[expandedItem] = expandedItem === key ? !expandedItems.value[key] : false;
			return acc;
		},
		{} as Record<string, boolean>
	);
};

const handleClickOpenSupport = () => {
	dispatchGAEvent({
		event: "click_button",
		location: "footer",
		button_name: "support"
	});
	handleSupportButtonClick();
};
</script>
<template>
	<footer class="footer">
		<div class="footer-top">
			<a class="logo" href="/">
				<NuxtImg :src="logoImg" alt="logo" width="146" height="80" />
			</a>
			<div class="footer-social">
				<NuxtLink
					v-for="social in data?.socialNetworks"
					:key="social?.id"
					:data-tid="`footer-social-${social?.id}`"
					:to="social?.url"
					external
					target="_blank"
					><span class="icon" v-html="social?.icon"
				/></NuxtLink>
			</div>
		</div>

		<div class="support">
			<div class="support-item">
				<AText variant="topeka" class="text-coro">{{ t("Need help?") }}</AText>
				<AText
					as="div"
					variant="toledo"
					:modifiers="['underline', 'semibold']"
					class="text-сirebon item"
					data-tid="footer-support"
					@click="handleClickOpenSupport"
				>
					{{ t("Online support") }}
					<NuxtIcon name="24/support" class="support-icon" filled />
				</AText>
			</div>

			<div class="support-item">
				<AText variant="topeka" class="text-coro">{{ t("Need help?") }}</AText>
				<AText
					as="a"
					variant="toledo"
					:modifiers="['underline', 'semibold']"
					href="mailto:support@funzcity.com"
					class="text-сirebon item"
					data-tid="footer-support-email"
				>
					support@funzcity.com
					<NuxtIcon name="24/mail" class="support-icon" filled />
				</AText>
			</div>
		</div>

		<div class="info">
			<div class="about">
				<div :class="['about-item', { active: expandedItems.about }]">
					<ACollapse :expanded="expandedItems.about">
						<template #title>
							<AText
								as="p"
								variant="taipei"
								data-tid="footer-about"
								:class="['about-title', expandedItems.about ? 'text-сirebon' : 'text-coro']"
								:modifiers="['medium']"
								@click="handleClick('about')"
							>
								{{ t("About Our Brand") }}
								<NuxtIcon name="32/arrow" filled :class="['about-icon', { expanded: expandedItems.about }]" />
							</AText>
						</template>

						<template #expanded>
							<AText as="p" variant="tempe" class="text-coro" :modifiers="['medium']">
								<p>
									{{
										t(
											"Welcome to FunzCity, your ultimate online gaming destination! Our platform boasts a vast array of games that cater to every taste and preference. From timeless classics to innovative and unique games like Hold'n'Link and Fishing ones, we ensure there’s something for every type of gamer. From free daily prizes to regular fun events, FunzCity is committed to keeping the fun going and the rewards flowing. Discover your next favorite game at FunzCity."
										)
									}}
								</p>

								<p>
									{{
										t(
											"FunzCity Social Gaming Platform is owned and operated by A1 Development LLC incorporated and registered in Wyoming, USA with company number 2019-000846205 whose registered office is at 571 S Washington, Afton, WY 83110, USA."
										)
									}}
								</p>

								<p>
									<i18n-t
										keypath="For contact, please email support ({mail}) or visit aone.llc. US payment related queries (24/7): {phone}. Correspondence address: A1 Development LLC, 3597 E Monarch Sky Lane Suite 240, Meridian, Idaho 83646, USA."
									>
										<template #mail>
											<NuxtLink to="mailto:support@funzcity.com" external class="link">
												<AText variant="tempe" class="text-сirebon" :modifiers="['underline']"
													>support@funzcity.com</AText
												>
											</NuxtLink>
										</template>
										<template #phone>
											<NuxtLink to="tel:+12088261426" external class="link">
												<AText variant="tempe" class="text-сirebon" :modifiers="['underline']">
													{{ t("+1 (208) 826-1426") }}
												</AText>
											</NuxtLink>
										</template>
									</i18n-t>
								</p>
							</AText>
						</template>
					</ACollapse>
				</div>

				<div :class="['about-item', { active: expandedItems.how }]">
					<ACollapse :expanded="expandedItems.how">
						<template #title>
							<AText
								as="p"
								variant="taipei"
								data-tid="footer-how-to-play"
								:class="['about-title', expandedItems.how ? 'text-сirebon' : 'text-coro']"
								:modifiers="['medium']"
								@click="handleClick('how')"
							>
								{{ t("How To Play") }}
								<NuxtIcon name="32/arrow" filled :class="['about-icon', { expanded: expandedItems.how }]" />
							</AText>
						</template>

						<template #expanded>
							<AText as="div" variant="tempe" class="text-coro" :modifiers="['medium']">
								<p>
									{{
										t("At FunzCity players dive into the action using Fun Coins (FC), our exclusive virtual currency.")
									}}
								</p>

								<p>
									{{
										t(
											"You can collect FC for free when signing up and accumulate them daily using the Magic Box feature. These Coins unlock access to a diverse array of games within FunzCity."
										)
									}}
								</p>

								<p>
									{{
										t(
											"Fun Coins cannot be exchanged, traded, or transferred for real currency, as they don't have any real value."
										)
									}}
								</p>
							</AText>
						</template>
					</ACollapse>
				</div>

				<div :class="['about-item', { active: expandedItems.additional }]">
					<ACollapse :expanded="expandedItems.additional">
						<template #title>
							<AText
								as="p"
								variant="taipei"
								data-tid="footer-additional"
								:class="['about-title', expandedItems.additional ? 'text-сirebon' : 'text-coro']"
								:modifiers="['medium']"
								@click="handleClick('additional')"
							>
								{{ t("Additional information") }}
								<NuxtIcon name="32/arrow" filled :class="['about-icon', { expanded: expandedItems.additional }]" />
							</AText>
						</template>

						<template #expanded>
							<AText as="p" variant="tempe" class="text-coro" :modifiers="['medium']">
								<p>
									{{
										t("At FunzCity players dive into the action using Fun Coins (FC), our exclusive virtual currency.")
									}}
								</p>

								<p>
									{{
										t(
											"You can collect FC for free when signing up and accumulate them daily using the Magic Box feature."
										)
									}}
								</p>
							</AText>
						</template>
					</ACollapse>
				</div>
			</div>
		</div>

		<div class="footer-bottom">
			<div class="terms-list">
				<NuxtLink
					v-for="(link, index) in termsLinks"
					:key="link?.slug"
					:data-tid="`footer-terms-${index}`"
					:to="`/page/${link?.slug}`"
				>
					<AText class="text-coro" :modifiers="['bold', 'underline']">{{ link?.title }}</AText>
				</NuxtLink>
			</div>

			<div class="copyright">
				<AText variant="topeka" class="text-coro" data-tid="footer-copyright" :modifiers="['semibold']">
					{{ t("© 2024 FunzCity Social Gaming Platform") }}
				</AText>
			</div>
		</div>
	</footer>
</template>
<style lang="scss" scoped>
.footer {
	display: flex;
	flex-direction: column;
	background-color: var(--carabanchel);
	border-top: 1px solid var(--cuttack);
	width: 100%;
	padding: gutter(8) gutter(8) gutter(4);
	gap: gutter(4);

	@media (max-width: 1365px) {
		padding: gutter(2.5) gutter(2.5) gutter(3);
	}

	@media (max-width: 768px) {
		padding: gutter(2.5) gutter(2) gutter(3);
	}

	&-top {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include media-breakpoint-down(md) {
			flex-direction: column;
			gap: gutter(1);
		}
	}

	&-social {
		display: flex;
		gap: gutter(2);
		&:deep(svg) {
			width: auto;
			height: 48px;
		}
	}

	&-bottom {
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include media-breakpoint-down(lg) {
			flex-direction: column;
			align-items: flex-start;
			padding-bottom: gutter(5);
		}
	}
}

.terms-list {
	display: flex;
	gap: gutter(1);

	@include media-breakpoint-down(lg) {
		flex-direction: column;
		gap: gutter(2);
	}

	a {
		&:hover {
			color: var(--cannes);
			span {
				text-decoration: none;
			}
		}
	}
}

.copyright {
	display: flex;
	align-items: center;
	justify-content: space-between;

	@include media-breakpoint-down(lg) {
		width: 100%;
		justify-content: center;
		padding-top: gutter(4);
	}
}

.info {
	display: flex;
	gap: gutter(7.5);
}

.support {
	display: flex;
	min-width: 216px;
	gap: gutter(2);
	position: relative;

	@include media-breakpoint-down(lg) {
		flex-direction: column;
	}

	.link {
		display: flex;
		align-items: center;
		gap: gutter(1);

		.nuxt-icon {
			font-size: 24px;

			&:deep(svg) {
				margin-bottom: gutter(0);
			}
		}
	}
}

.support-icon:deep(svg) {
	font-size: 24px;
	margin: 0;

	path {
		fill: var(--сirebon);
	}
}

.support-item {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	flex: 1;
	height: 78px;
	padding: gutter(2) gutter(1.5);
	background: var(--chifeng);
	border: 2px solid var(--chifeng);
	transition: linear 0.2s;
	cursor: pointer;
	border-radius: 20px;

	&:hover {
		border-color: var(--сirebon);
	}

	.item {
		display: flex;
		justify-content: space-between;
		width: 100%;
		align-items: center;

		&:hover {
			cursor: pointer;
		}
	}
}

.about {
	width: 100%;

	&-title {
		display: flex;
		justify-content: space-between;

		&:hover {
			cursor: pointer;
		}
	}

	&-icon.expanded:deep(svg path) {
		fill: var(--сirebon);
	}

	&-item {
		display: flex;
		width: 100%;
		flex-direction: column;
		justify-content: space-between;
		border-bottom: 2px solid var(--clara);
		padding: gutter(3) gutter(0);

		&.active {
			border-color: var(--сirebon);
		}

		&:deep(svg) {
			width: 32px;
			height: 32px;
			margin-bottom: 0;
		}

		p {
			padding: gutter(1) 0;
		}
	}
}

.expanded {
	transform: rotate(180deg);
}
</style>
